import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import api from "../../../api";
import config from "../../../config";
import { pageObj } from "../../../generalFunction/pageObj";

export function useLogo(props){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            value : '',
            type : 'text',
            show : false,
            label : 'ID',
            errorMsg : '',
            showError : false,            
            required : false,
            readOnly : true
        },
        logo_path : {
            name : 'logo_path',
            value : null,
            type : 'file',
            show : true,
            label : 'Logo',
            errorMsg : '',
            showError : false,            
            required : true
        },
        url_name : {
            name : 'url_name',
            value : '',
            type : 'text',
            show : true,
            label : 'Logo Name',
            errorMsg : '',
            showError : false,            
            required : true
        },
    })

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                offset : offset,
                limit : limit
            }
            if (q){
                params['q'] = q;
            }

            const res = await api.get(`${config.endPoint.masterLogo}`, {params: params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setPageValue, setIsLoading, setAlertValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))
        }
    }

    const handleChangeImage = async(e) => {       
        var nfile = e.target.files[0];        
        var reader = new FileReader();
        reader.readAsDataURL(nfile);
        reader.onloadend = function() {
            setFormData(formData => ({...formData, logo_path : {...formData.logo_path, value : reader.result}}))
        }
    };

    const handleAdd = () => {
        setView('create');
        setIsEdit(false);
        clearForm();
    }
    
    const clearForm = () => {
        setFormData(formData => ({...formData,
            logo_path : {...formData.logo_path, value : ''},
            url_name : {...formData.url_name, value : ''},
        }))
    }
    
    const handleCancel = () => {
        setView('list');
        clearForm();
    }

    const handleEdit = (post) => {
        setFormData(formData => ({...formData,
            logo_path : {...formData.logo_path, value : post.logo_path},
            url_name : {...formData.url_name, value : post.url_name},
            id : {...formData.id, value : post.id},
        }));
        setIsEdit(true);
        setView('create')
    }
    
    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let tmpForm = {}
            let countError = 0;
            let obj = {...formData}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                    console.log('index >>>>', index, post.value)
                }else{
                    tmpForm[index] = post.value
                }
                return true;
            })

            

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)                
                return;
            }

            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.masterLogo}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.masterLogo}`, newForm).then(res => res.data);                
            }            
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setView('list');
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true, text : `Apakah anda yakin akan menghapus logo ${post.url_name} ? `, id : post.id,
            title : 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async() => {
        try{
            const res = await api.delete(`${config.endPoint.masterLogo}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, handleChange, handleChangeImage, handleBlur, formData,
        handleAdd, handleCancel, handleEdit, handleDelete, handleSubmit, setListData, 
        isEdit, view, listData, handleChangeSearch, handleKeyDownSearch, handleSearch, handleSubmitDelete
    }
}