import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import config from "../config";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faGear, faChevronDown, faBell, faUser, faGears, faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { UserContext } from "../context/UserContext";


const DefaultHeader = () => {    
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [userInfo, setUserInfo] = useState(null);
    const myState = useContext(UserContext);
    const [sidebarIsOpen, setSidebarIsOpen] = myState.stateSidebar;
    const [showMenuProfile, setShowMenuProfile] = useState(true);

    useEffect(() => {
        const myCookies = cookies.get(config.cookiesName);
        if (myCookies && myCookies.user_info) {
            setUserInfo(myCookies.user_info)
        }
        // eslint-disable-next-line
    },[])

    const handleChangePassword = () => {
        navigate('/change-password')
    }

    const handleLogout = () => {
        cookies.remove(config.cookiesName, { path: '/' });		
		navigate('/login');
    }

    return (
        <>
            <header className="header text-sm absolute w-full top-0 bg-white flex items-center justify-between h-[72px] text-font-color" style={{zIndex: 10}}>
                <div className='pl-8 w-[256px]'>
                    <div className='font-bold flex flex-row justify-between'>
                        <div className="flex-1">Dashboard</div>
                        <div 
                            className='flex-1 font-bold cursor-pointer text-right'                         
                            onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </div>
                    </div>
                </div>
                
                <div className='pr-8' style={{justifySelf: 'flex-end', alignItems: 'flex-end'}}>
                    <div className="flex justify-right text-right">
                        <div className='text-sm justify-start text-lime-700 cursor-pointer' onClick={handleChangePassword}>Change Password</div>
                        <div className='text-sm justify-start mx-2'><FontAwesomeIcon icon={faGear} className='text-icon text-xl' /></div>
                        <div className='text-sm justify-start mx-2'><FontAwesomeIcon icon={faBell} className='text-icon text-xl' /></div>
                        <div className='flex ml-2 cursor-pointer' onClick={() => setShowMenuProfile(!showMenuProfile)}>                            
                            <div className='text-sm justify-start'>{ userInfo ? userInfo : 'Unknow User'}<span><FontAwesomeIcon icon={faChevronDown} className='ml-2  text-icon' style={{fontSize: 10}} /></span></div>
                        </div>
                    </div>
                </div>
                <div className={`absolute profile-menu ${showMenuProfile ? 'm-visible' : 'm-invisible'} transition-all delay-150 duration-300 right-[30px] top-[60px]`}>
                    <div className={`w-[200px] bubble bg-white`}>
                        <ul>
                            <li className='text-black cursor-pointer'>
                                <div className="flex flex-row align-middle transition ease-in-out delay-150 hover:translate-x-3">
                                    <div className='max-w-[10px]'>
                                        <FontAwesomeIcon icon={faUser} />
                                    </div>
                                    <div>Profile</div>
                                </div>
                            </li>
                            <li className='text-black cursor-pointer transition ease-in-out hover:translate-x-3'>
                                <div className="flex flex-row align-middle">
                                    <div className='max-w-[10px]'>
                                        <FontAwesomeIcon icon={faGears} />
                                    </div>
                                    <div>Settings</div>
                                </div>
                            </li>
                            <li className='gray'>
                                <div className="flex flex-row align-middle justify-center" onClick={handleLogout}>
                                    <div className='max-w-[10px]'>
                                        <FontAwesomeIcon icon={faPowerOff} />
                                    </div>
                                    <div>Logout</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                
            </header>            
        </>
    )
}
export default DefaultHeader;