import { Fragment } from "react"
import PropTypes from 'prop-types';

const AlertComp = (props) => {
    const { text, color, isOpen, onClose } = props;

    const getBgColor = (params) => {
        if (params === 'danger'){
            // return "bg-[rgba(254, 242, 242, 0.5)]"
            return "bg-[rgba(254,242,242,0.8)]"
        }else if (params === 'success'){
            return "bg-[#d4edda]"
        }
    }

    const getTextColor = (params) => {
        if (params === 'danger'){
            return "text-red-800"
        }else if (params === 'success'){
            return "text-green-950"
        }
    }

    return(
        <Fragment>
            { isOpen &&
                <div className={`fixed w-full h-10 ${getBgColor(color)} flex items-center ${getTextColor(color)} justify-center inset-0 z-100 outline-none focus:outline-none`} style={{zIndex: 12}}>
                {/* <div className={`fixed w-full h-10 bg-[rgba(0,0,0,0.5)] flex items-center ${getTextColor(color)} justify-center inset-0 z-55 outline-none focus:outline-none`}> */}
                    <div className='grow flex justify-center'>{text}</div>
                    <div className='flex-none px-5 cursor-pointer' onClick={onClose}>x</div>
                </div>
            }
        </Fragment>
    )
}

AlertComp.propTypes = {
    text : PropTypes.string,
    color : PropTypes.string,
    isOpen : PropTypes.bool,
    onClose : PropTypes.func,
}

AlertComp.defaultProps = {
    isOpen : false
}
export default AlertComp;