import { useCallback, useEffect, useRef, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import axios from "axios";
import Cookies from 'universal-cookie';
import config from "../../../config";
import api from "../../../api";
import { pageObj } from "../../../generalFunction/pageObj";

export function useVideo(prosp){
    const cookies = new Cookies();
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
     } = useGlobalFunction();
    const [videoSrc , setVideoSrc] = useState("aFJ6YXOSeG6v5h3eyM4iXg==");
    const [view, setView] = useState('list');
    const [listData, setListData] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [modalVideo, setModalVideo] = useState(false);
    const videoRef = useRef(null);
    const [formData, setFormData] = useState({    
        id : {
            name : 'id',
            value : '',
            tipe : 'text',
            show : false,
            label : 'Title',
            errorMsg : '',
            showError : false,            
            required : false
        },             
        title : {
            name : 'title',
            value : '',
            tipe : 'text',
            show : true,
            label : 'Title',
            errorMsg : '',
            showError : false,            
            required : true
        },             
        file_media : {
            name : 'file_media',
            value : null,
            tipe : 'file',
            show : false,
            label : 'Start Date',
            errorMsg : '',
            showError : false,            
            required : true
        },
    })

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage, page=1) => {
        setIsLoading(true);
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.video}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),                    
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }, [setAlertValue, setIsLoading, setPageValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handlePaginate = (page) => {        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))
        }
    }

    const handleChangeVideo = async(e) => {
        const file = e.target.files[0];
        console.log('file >>>', URL.createObjectURL(file))
        console.log(file)        
        let url = URL.createObjectURL(file)
        setVideoSrc(url);        

        // var reader = new FileReader();
        // reader.readAsDataURL(file);
        // console.log('reader >>>>', url)
        // setFormData(formData => ({...formData,
        //     file_media : {...formData.file_media, value : reader.result}
        // }))
        // const base64 = await convertBase64(file)        
        // console.log('base64 >>>>', url)
        // setFormData(formData => ({...formData,
        //     file_media : {...formData.file_media, value : base64}
        // }))

        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = function() {			
			setFormData(formData => ({...formData, 
				file_media : {...formData.file_media, value: file},				
			}))
        }
    };

    const handleAdd = () => {
        setView('create');
        setFormData(formData => ({...formData,
            title : {...formData.title, value : ''},
            file_media : {...formData.file_media, value : '', required: true},
        }));
        setVideoSrc('');
        setIsEdit(false);
    }
    
    
    const handleEdit = (post) => {
        setFormData(formData => ({...formData,
            title : {...formData.title, value : post.title},
            id : {...formData.id, value : post.id},
            file_media : {...formData.file_media, value : '', required: false},
        }));        
        setVideoSrc(post.id_display);
        setIsEdit(true);
        setView('create');
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let tmpForm = {}
            let countError = 0;
            let obj = {...formData}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;
                    console.log('index >>>>', index, post.value)
                }else{
                    tmpForm[index] = post.value
                }
                return true;
            })
            let headers = {
                'Content-Type': 'multipart/form-data',                
            }
            const myCookies = cookies.get(config.cookiesName);             
            if (myCookies && myCookies.token) {                    
                headers['Authorization'] = 'Token ' + myCookies.token;
            }
            

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}))
                }, config.timeOutValue)                
                return;
            }

            

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            if (!isEdit){
                newForm.append('fileVideo', formData.file_media.value);
            }
            // const res = await api.post(`${config.endPoint.postCampaign}`, newForm).then(res => res.data);
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.video}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await axios.post(`${config.apiBaseUrl[process.env.NODE_ENV]}${config.endPoint.postVideo}`, newForm, {headers: headers}).then(res => res.data);
            }
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
                setView('list');
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            // console.log('error >>>>', error);
        }
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true, text : `Apakah anda yakin akan menghapus user ${post.title} ? `, id : post.id,
            title : 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async() => {
        try{
            const res = await api.delete(`${config.endPoint.video}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handleClickVideo = async(id) => {        
        setVideoSrc(id);
        setModalVideo(true);
    }

    const handleCloseModalVideo = () => {
        setModalVideo(false);
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, videoSrc, handleAdd, handleCancel, formData, handleChangeVideo, handleSubmit,
        handleChange, handleBlur, view, handlePaginate, handleSearch, handleChangeSearch, handleKeyDownSearch,
        handleDelete, handleSubmitDelete, listData, handleClickVideo, handleCloseModalVideo, videoRef, 
        modalVideo, isEdit, handleEdit
    }
}