import ReactLoading from 'react-loading';

const LoadingComp = (props) => {
    return(
        <div className='absolute top-1/2 left-1/2'>
            <ReactLoading type={'cylon'} color="#0366fc" />
        </div>
    )
}

export default LoadingComp;