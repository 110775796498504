import { Fragment } from "react"
import PropTypes from 'prop-types';
import { Input, Label } from "../forms";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSearch } from '@fortawesome/free-solid-svg-icons'


const InputComp = ({ 
    type, value, 
    onChange, onBlur, placeholder, name, className, obj, 
    showPassword, handleShowPassword, inputType, handleClick,
    readOnly 
}) => {
    return (
        <>
            { ['text', 'password'].includes(type) &&
                <Fragment>
                    {['password'].includes(inputType) &&
                        <div className="relative">
                            <Input
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className={`block ${className}`}
                                name={name}
                                type={type}
                                readOnly={readOnly}
                            />
                            { inputType === 'password' &&                
                                <div style={{position: 'absolute', bottom: 12, right: 12}} className="cursor-pointer" onClick={() => handleShowPassword(name)}>
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye}/>
                                </div>
                            }
                        </div>
        
                    }
                    {['text'].includes(inputType) &&
                        <div className="relative">
                            <Input
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                className={`block ${className}`}
                                name={name}
                                type={type}
                                readOnly={readOnly}
                            />
                        </div>
        
                    }
                </Fragment>
            }
            { type === 'input_button' &&
                <div className="flex flex-row">
                    <div className='w-full ' style={{border : '1px solid #e4e7ea', borderRight: 'none', borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem'}}>
                        <Input 
                            className='text-sm appearance-none 
                                block w-full bg-white
                                text-gray-700
                                border-gray-400 
                                py-3 
                                px-2
                                border-none
                                leading-tight 
                                focus:outline-0
                                focus:outline-none 
                                focus:outline-blue-400 
                                focus:ring-0
                                focus:border-none
                                read-only:bg-gray-100'
                            value={value}
                            onChange={onChange}
                            onBlur={onBlur}
                            name={name}
                            style={{borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem'}}
                            readOnly={readOnly}
                        />
                    </div>
                    <div className='bg-blue-900 rounded-r-sm border border-blue-900 flex-grow cursor-pointer'
                        style={{borderTopRightRadius: '.25rem', borderBottomRightRadius: '.25rem'}}
                        onClick={handleClick}
                    >
                        <div className="px-4 mt-[12px] text-white">
                            <FontAwesomeIcon icon={faSearch}/>
                        </div>
                    </div>
                </div>
            }
            { type === 'file' &&
                <div className="relative">
                    <Input
                        placeholder={placeholder}
                        // value={value}
                        onChange={onChange}
                        // onBlur={onBlur}
                        className={`block ${className}`}
                        name={name}
                        type={type}
                    />
                </div>
            }
            { type === 'select' &&
                <select 
                    onChange={onChange} 
                    onBlur={onBlur} 
                    name={name} 
                    value={value} 
                    id="countries" 
                    className="bg-gray-50 
                        border 
                        border-gray-300 
                        text-gray-900 
                        text-sm 
                        rounded-lg 
                        focus:ring-blue-500 
                        focus:border-blue-500 
                        block w-full p-2.5 
                        dark:bg-gray-700 dark:border-gray-600 
                        dark:placeholder-gray-400 dark:text-white 
                        dark:focus:ring-blue-500 
                        dark:focus:border-blue-500
                    ">
                    { obj.map((post, index) => {
                        return(
                            <option value={post.value} key={index}>{post.label}</option>
                        )
                    })}
                </select>
            }
        </>
    )
}

const FormTextSingleComp = (props) => {
    const { type, onChange, onBlur, value, name, show,
        flexType,
        label,
        placeholder, showError, className, obj, showPassword, handleShowPassword, inputType,
        handleClick,
        readOnly
    } = props;
    return (
        <Fragment>
            {show &&
                <>
                    {flexType === 'row' &&
                        <div className={`w-full grid grid-cols-4 gap-4`}>
                            <div>
                                <Label>{label}</Label>
                            </div>
                            <div className='col-span-3'>
                                <InputComp
                                    placeholder={placeholder}
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    type={type}
                                    name={name}
                                    className={className}
                                    obj={obj}
                                    showPassword={showPassword}
                                    handleShowPassword={handleShowPassword}
                                    inputType={inputType}
                                    handleClick={handleClick}
                                    readOnly={readOnly}
                                />
                                {showError &&
                                    <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                                }
                            </div>
                        </div>
                    }
                    {flexType === 'column' &&
                        <div className="grid px-3 mb-5">
                            <Label className=''>{label}</Label>                            
                            <InputComp
                                placeholder={placeholder}
                                value={value}
                                onChange={onChange}
                                onBlur={onBlur}
                                type={type}
                                name={name}
                                className={className}
                                obj={obj}
                                showPassword={showPassword}
                                handleShowPassword={handleShowPassword}
                                inputType={inputType}
                                handleClick={handleClick}
                                readOnly={readOnly}
                            />
                            {showError &&
                                <p className="text-red-500 text-xs italic">Please fill out this field.</p>
                            }
                        </div>
                    }
                </>
            }
        </Fragment>
    )
}

FormTextSingleComp.propTypes = {
    type: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    name: PropTypes.string,
    show: PropTypes.bool,
    flexType: PropTypes.string,
    placeholder: PropTypes.string,
    showError: PropTypes.bool,
    className: PropTypes.string,
    obj: PropTypes.array,
    showPassword : PropTypes.bool,
    handleShowPassword : PropTypes.func,
    inputType : PropTypes.string,
    handleClick : PropTypes.func,
    readOnly: PropTypes.bool,
}

FormTextSingleComp.defaultProps = {
    show: true,
    value: '',
    type: 'text',
    placeholder: '',
    flexType: 'column',
    showError: false,
    className : '',
    obj : [],
    showPassword : true,
    inputType : 'text',
    handleClick : null,
    readOnly: false,
}

export default FormTextSingleComp;