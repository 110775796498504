import { Outlet } from 'react-router-dom';
import DefaultSideBar from './DefaultSideBar';
import DefaultHeader from './DefaultHeader';
import Cookies from 'universal-cookie';
import { useCallback, useEffect, useState } from 'react';
import config from '../config';
import api from '../api';


const DefaultLayout = () => {
    const cookies = new Cookies();
    const [developmentMode, setDevelopmentMode] = useState(false);

    useEffect(() => {
        const myCookies = cookies.get(config.cookiesName);
        if (myCookies && myCookies.development){
            setDevelopmentMode(true);
        }
        // eslint-disable-next-line
    },[])

    const checkLogin = useCallback(async() => {
        try{
            await api.get(`${config.endPoint.checkLogin}`).then(res => res.data);
            console.log('login', developmentMode)
            return
        }catch(error){
            let status = error && error.response && error.response.status ? error.response.status : '';
            if (status && status === 401) {
                return window.location.href = "/login"
            }
        }
        // eslint-disable-next-line
    },[])

    useEffect(() => {
        checkLogin();
    },[checkLogin])
    return (
        <div className="app bg-body-silver text-font-color overflow-x-hidden relative">
            <DefaultHeader/>
            <div className="relative md:flex md:h-screen overflow-hidden pt-[72px]">
                <DefaultSideBar />
                <div className="flex-1 overflow-y-auto">
                    <div className="p-7 main-content">
                        <Outlet />
                    </div>
                </div>
                {/* { developmentMode &&
                    <div 
                        className="label-development"
                        >
                        Development
                    </div>
                } */}
            </div>
        </div>
        // <div>blah</div>
    );
};

export default DefaultLayout;