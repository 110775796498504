import CardComp from "../../../component/globalComponent/CardComp"
import { useUser } from "./userFunction";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faCircle, faDotCircle } from "@fortawesome/free-regular-svg-icons"
import { faPlus, } from "@fortawesome/free-solid-svg-icons"
import PagePagination from "../../../component/globalComponent/PagePagination";
import FormTextSingleComp from "../../../component/globalComponent/FormTextSingleComp";
import ReactLoading from 'react-loading';
import ButtonGroup from "../../../component/globalComponent/ButtonGroup";
import Modal from "../../../component/Modal";
import { Fragment } from "react";
import SearchComp from "../../../component/globalComponent/SearchComp";
import Button from "../../../component/globalComponent/Button";


const UserPage = (props) => {
    const {
        listData, isLoading, handleChange, handleBlur, handleSubmit,
        handleCancel, handleAdd, pageValue, handlePaginate, formData,
        view, handleEdit, handleDelete, listOutlet, handleClickOutlet,
        handleShowPassword, groupName, selectedOutlet, modalValue, handleCloseModal,
        handleSubmitDelete, handleKeyDownSearch, handleChangeSearch, handleSearch
    } = useUser();



    const buttonNode = () => {
		return (
			<ButtonGroup>
				<Button
					onClick={handleSubmit}
                    color="purple-light"
				>
					Save
				</Button>
				<Button
					onClick={handleCancel}
					color="light"
				>
					Cancel
				</Button>
			</ButtonGroup>
		);
	};
    return (
        <Fragment>
            
            <div className="z-12">
                <CardComp
                    title={"User"}
                    ButtonNode={view === 'create' ? buttonNode : null}
                >
                    {view === 'list' &&
                        <div className='px-5'>
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <div className='col-span-3'>
                                    <ButtonGroup>
                                        <Button
                                            color="purple-light"
                                            onClick={handleAdd}
                                        >
                                            <FontAwesomeIcon icon={faPlus} /> Add User
                                        </Button>                
                                    </ButtonGroup>
                                </div>
                                <SearchComp
                                    pageValue={pageValue}
                                    handleChangeSearch={handleChangeSearch}
                                    handleSearch={handleSearch}
                                    handleKeyDownSearch={handleKeyDownSearch}
                                />
                            </div>
                            <div className="relative">
                                <table className='table-auto w-full table'>
                                    <thead>
                                        <tr>
                                            <th className='text-right'>#</th>
                                            <th>Name</th>
                                            <th className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listData.map((post, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td className='text-right'>{pageValue.offset + 1 + index}</td>
                                                    <td>{post.first_name}</td>
                                                    <td className='text-center'>
                                                        <ButtonGroup>
                                                            <Button onClick={() => handleEdit(post)} className='button btn-icon bg-blue-400 px-5 py-2 btn-danger text-white' color="primary-outline">
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                            <Button onClick={() => handleDelete(post)} className='button btn-icon bg-red-400 px-5 py-2 btn-danger text-white' color="danger-outline">
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className='mt-5'>
                                <PagePagination
                                    data={pageValue.obj}
                                    handlePagination={handlePaginate}
                                    page={pageValue.page}
                                    lastPage={pageValue.lastPage}
                                />
                            </div>
                        </div>
                    }
                    {view === 'create' &&
                        <div className='mt-5 px-5'>
                            <div className='grid grid-cols-2 gap-5'>
                                <div>
                                    {Object.entries(formData).map(([index, post]) => {
                                        if (post.show) {
                                            return (
                                                <div key={index}>
                                                    <FormTextSingleComp
                                                        {...post}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        handleShowPassword={handleShowPassword}
                                                    />
                                                </div>
                                            )
                                        }
                                        return true;
                                    })}
                                </div>
                                <div>
                                    <div>
                                        <div className='font-semibold text-base border-b pb-2'>Group Outlet</div>
                                        <div className='mt-5'>
                                            { groupName === 'Outlet' ?
                                                <>
                                                    {listOutlet.map((outlet, index) => {
                                                        return (
                                                            <div className='flex flex-row items-center mb-2' key={index}>
                                                                <div className='cursor-pointer mr-3' onClick={() => handleClickOutlet(outlet)}>
                                                                    <FontAwesomeIcon icon={selectedOutlet.includes(outlet.id) ? faDotCircle : faCircle } />
                                                                </div>
                                                                <div>{outlet.nama}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <>
                                                    {listOutlet.map((outlet, index) => {
                                                        return (
                                                            <div className='flex flex-row items-center mb-2' key={index}>
                                                                <input
                                                                    type="checkbox"
                                                                    className='mr-2'
                                                                    onClick={() => handleClickOutlet(outlet)}
                                                                    checked={selectedOutlet.includes(outlet.id)}
                                                                    onChange={() => null}
                                                                />
                                                                <div>{outlet.nama}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    {isLoading &&
                        <div className='w-full flex flex-row justify-center'>
                            <ReactLoading type={'cylon'} color="#0366fc" className="text-center" />
                        </div>
                    }

                </CardComp>
                <Modal
                    isOpen={modalValue.show}
                    onClose={handleCloseModal}
                    title={modalValue.title}
                    onSubmit={handleSubmitDelete}
                    onSubmitText={"Delete"}
                    submitColor={"danger"}
                >
                    {modalValue.text}
                </Modal>
            </div>
        </Fragment>
    )
}
export default UserPage