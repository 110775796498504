import PropTypes from 'prop-types';

const Input = (props) => {
    const { 
        type, placeholder, id, onChange, onBlur, value,
        className, name, readOnly
    } = props;
    return(
        <input 
            className={`text-sm appearance-none 
                block w-full bg-white
                text-gray-700
                border-gray-400 
                rounded py-3 
                px-2
                border 
                leading-tight 
                focus:outline-1 
                focus:outline-none 
                focus:outline-blue-400 
                focus:border-none ${className}
                read-only:bg-gray-100`
            }
            id={id}
            type={type} 
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            name={name}
            readOnly={readOnly}
        />
    )
}
Input.propTypes = {
    type: PropTypes.string,
    placeholder : PropTypes.string,
    id : PropTypes.string,
    value : PropTypes.any,
    onChange : PropTypes.func,
    onBlur : PropTypes.func,
    className : PropTypes.string,
    name : PropTypes.string,
    readOnly: PropTypes.bool
}

Input.defaultProps = {
    placeholder : '',
    type: 'text',
    id : '',
    className : '',
    readOnly : false,
}
export default Input;