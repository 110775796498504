import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser,  } from "@fortawesome/free-regular-svg-icons"
import { faDashboard, faStore, 
    // faUserGroup, 
    faVideo,  faMoneyBill,
    faCreditCard, faImage, faGlobe
} from "@fortawesome/free-solid-svg-icons";
import Cookies from 'universal-cookie';
import config from "../config";
import { UserContext } from "../context/UserContext";

import {  faChevronDown, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const DefaultSideBar = () => {
    const myState = useContext(UserContext);
    const [sidebarIsOpen] = myState.stateSidebar;

    const location = useLocation();
    const { pathname } = location;
    const [menuOpen, setMenuOpen] = useState('');
    
    const navigate = useNavigate();
    const cookies = new Cookies();
    let [Menus, setMenus] = useState([
    ]);
    
    useEffect(() => {
        const myCookies = cookies.get(config.cookiesName);
        let tmp = [
            { title: "Dashboard", src: "Chart_fill" , path : '/', icon : faDashboard},
        ]
        if (myCookies && myCookies.is_superuser) {
            tmp.push(
                { title: "Video", src: "Chart_fill" , path : '/video', icon : faVideo},
                { title: "Logo", src: "Chart_fill" , path : '/logo', icon : faImage},
                { title: "Base Endpoint", src: "Chart_fill" , path : '/base-endpoint', icon : faGlobe},
                { title: "Outlet", src: "Chart_fill" , path : '/outlet', icon : faStore},
                // { title: "Group Permission", src: "Chart_fill" , path : '/group-permissions', icon : faUserGroup},
                { title: "Users", src: "Chart_fill" , path : '/users', icon : faUser},
                { title: "Master Va", src: "Chart_fill" , path : '/master-va', icon : faCreditCard},
                { title: "Maximal Va", src: "Chart_fill" , path : '/maximal-va', icon : faCreditCard},
            )            
        }
        tmp.push(            
            { title: "Transaksi", src: "Chart_fill" , path : '/transaksi', icon : faMoneyBill},
        )
        setMenus(tmp);
        // eslint-disable-next-line
    },[])

    const handleOpenMenu = (params, index) => {
        setMenuOpen(params);
        let array = [...Menus];
        array[index] = {...array[index], isOpen : !array[index].isOpen}
        setMenus(array);
    }


   
   

    return (
        <div className={`${sidebarIsOpen ? 'w-[280px] sidebaropen' : 'w-20 sidebarclose'} duration-300 h-screen  bg-white relative pt-[5px]  pb-[55px] sidebar`}>
            <ul className='pt-0'>
                {Menus.map((Menu, index) => (
                    <li
                        key={index}
                        className={`
                            rounded-md
                            cursor-pointer 
                            hover:bg-light-white
                            gap-x-3
                            ${Menu.gap ? "mt-9" : "mt-2"} ${pathname === Menu.path && "bg-light-white"} 
                            ${pathname === Menu.path && 'active'}`
                        }
                    >
                        <div 
                            className='flex  
                            justify-between items-center p-2 pl-[15px]'
                            onClick={() => Menu.child ? handleOpenMenu(Menu.id, index) : navigate(Menu.path)}
                        >
                            <div className='flex items-center'>
                                <FontAwesomeIcon icon={Menu.icon} className='text-icon mr-[15px]' />
                                <span className={`${!sidebarIsOpen && "hidden"} origin-left duration-200`}>
                                    {Menu.title}
                                </span>
                            </div>
                            {Menu.child && sidebarIsOpen &&
                                <>
                                    <FontAwesomeIcon icon={menuOpen === Menu.id && Menu.isOpen ? faChevronDown : faChevronRight} className='ml-2  text-icon' style={{ fontSize: 10 }} />
                                </>
                            }
                        </div>                        
                        {Menu.child && Menu.isOpen && sidebarIsOpen &&
                            // <ul className={`submenu ${Menu.isOpen && Menu.child.map(x => x.path).includes(pathname) ? 'isOpen mt-[5px]' : 'scale-0 isClose'} duration-100`}>
                            <ul className={`submenu mt-[5px] duration-100`}>
                                {Menu.child.map((childRow, childrenIndex) => {
                                    return (
                                        <li
                                            key={`${index}${childrenIndex}`}
                                            // className={`cursor-pointer pl-[30px] ${Menu.isOpen && Menu.child.map(x => x.path).includes(pathname) && "mt-2"} p-2  gap-x-3 ${pathname === childRow.path && 'active'}`}
                                            className={`cursor-pointer pl-[30px] mt-2 p-2  gap-x-3 ${pathname === childRow.path && 'active'}`}
                                            onClick={() => navigate(childRow.path)}
                                        >
                                            <div
                                                className='flex  
                                                    justify-between 
                                                    items-center'
                                            >
                                                <div className='flex items-center'>
                                                    { childRow.icon &&
                                                        <FontAwesomeIcon icon={childRow.icon} className='text-icon mr-3' />
                                                    }
                                                    <span className={`${!sidebarIsOpen && "hidden"} origin-left duration-200`}>
                                                        {childRow.title}
                                                    </span>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        }
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default DefaultSideBar;