import { Fragment } from "react"
import { useVaBank } from "./vaBankFunction"
import CardComp from "../../../component/globalComponent/CardComp";
import FormTextSingleComp from "../../../component/globalComponent/FormTextSingleComp";
import LoadingComp from "../../../component/globalComponent/LoadingComp";
import ButtonGroup from "../../../component/globalComponent/ButtonGroup";
import Button from "../../../component/globalComponent/Button";

const VaBankPage = (props) => {
    const {
        isLoading, formData, handleChange, handleBlur,
        handleSubmit, view, handleInsertFoto, listData, handleChangeSwith,
        handleAdd, handleCancel
    } = useVaBank();

    const myButtonNode = () => {
		return (
			<ButtonGroup>
				<Button
					onClick={handleSubmit}
                    color="purple-light"
				>
					Save
				</Button>
				<Button
					onClick={handleCancel}
					color="light"
				>
					Cancel
				</Button>
			</ButtonGroup>
		);
	};

    return (
        <Fragment>

            <CardComp
                title="Master VA"
                ButtonNode={view === 'create' ? myButtonNode : null}
            >
                {isLoading &&
                    <LoadingComp />
                }
                {view === 'list' &&
                    <div className='px-5'>
                        <div className="mb-5 text-right">
                            <ButtonGroup>
                                <Button className='button bg-blue-500  h-full text-white' onClick={handleAdd} color="purple-light">Tambah</Button>
                            </ButtonGroup>
                        </div>
                        <div className="relative">
                            <table className='table-auto w-full table'>
                                <thead>
                                    <tr>
                                        <th className='text-right'>#</th>
                                        <th>Bank</th>
                                        <th className='text-center'>Aktifkan</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData.map((post, index) => {
                                        return (
                                            <tr key={index}>
                                                <td className="text-right">{index + 1}</td>
                                                <td>
                                                    <div className='mb-2'>
                                                        {post.nama}
                                                    </div>
                                                    <img src={post.logo} alt="logo" className='max-h-[30px]' />
                                                </td>
                                                <td className='text-center'>
                                                    <input
                                                        className="mr-2 mt-[0.3rem] 
                                                            h-3.5 w-8 appearance-none 
                                                            rounded-[0.4375rem] 
                                                            bg-neutral-300 
                                                            before:pointer-events-none 
                                                            before:absolute before:h-3.5 
                                                            before:w-3.5 before:rounded-full 
                                                            before:bg-transparent before:content-[''] 
                                                            after:absolute after:z-[2] 
                                                            after:-mt-[0.1875rem] after:h-5 after:w-5 
                                                            after:rounded-full after:border-none 
                                                            after:bg-neutral-100 
                                                            after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] 
                                                            after:transition-[background-color_0.2s,transform_0.2s] 
                                                            after:content-[''] 
                                                            checked:bg-primary 
                                                            checked:after:absolute 
                                                            checked:after:z-[2] 
                                                            checked:after:-mt-[3px] 
                                                            checked:after:ml-[1.0625rem] 
                                                            checked:after:h-5 checked:after:w-5 
                                                            checked:after:rounded-full 
                                                            checked:after:border-none 
                                                            checked:after:bg-primary 
                                                            checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] 
                                                            dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                                                        type="checkbox"
                                                        role="switch"
                                                        id="flexSwitchCheckDefault"
                                                        onChange={(e) => handleChangeSwith(e, index)}
                                                        defaultChecked={post.is_active}
                                                     />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
                {view === 'create' &&
                    <div className='grid grid-cols-3 gap-2'>
                        <div></div>
                        <div>
                            <div>
                                <FormTextSingleComp
                                    {...formData.nama}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                            <div>
                                <FormTextSingleComp
                                    {...formData.logo}
                                    onChange={handleInsertFoto}
                                />
                                {formData.logo.value &&
                                    <div className="foto-container px-3 max-w-[240px]">
                                        <img src={formData.logo.value} alt="foto" />
                                    </div>
                                }
                            </div>
                            <div>
                                <FormTextSingleComp
                                    {...formData.script_kode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </div>
                        </div>
                        <div></div>
                    </div>
                }
            </CardComp>
        </Fragment>
    )
}

export default VaBankPage