import { useCallback, useContext, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import api from "../../../api";
import config from "../../../config";
import { pageObj } from "../../../generalFunction/pageObj";
import { UserContext } from "../../../context/UserContext";

export function useOutlet(props) {
    const {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const myState = useContext(UserContext);
    const [modalState, modalDispatch] = myState.modalState
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id: {
            name: 'id',
            value: '',
            type: 'text',
            show: true,
            label: 'ID',
            errorMsg: '',
            showError: false,
            required: false,
            readOnly: true,
        },
        nama: {
            name: 'nama',
            value: '',
            type: 'text',
            show: true,
            label: 'Name',
            errorMsg: '',
            showError: false,
            required: true,
        },
        logo: {
            name: 'logo',
            value: '',
            type: 'text',
            show: true,
            label: 'Logo',
            errorMsg: '',
            showError: false,
            required: true,
        },
        logo_display: {
            name: 'logo_display',
            value: '',
            type: 'input_button',
            show: true,
            label: 'Logo',
            errorMsg: '',
            showError: false,
            required: true,
            readOnly: true
        },
        logo_img: {
            name: 'logo_img',
            value: '',
            type: 'text',
            show: false,
            label: 'Logo',
            errorMsg: '',
            showError: false,
            required: true,
            readOnly: true
        },
        invoice_display: {
            name: 'invoice_display',
            value: '',
            type: 'text',
            show: true,
            label: 'Display Invoice',
            errorMsg: '',
            showError: false,
            required: true,
            readOnly: false,
            placeholder : 'Nama AHASS'
        },
        base_endpoint: {
            name: 'base_endpoint',
            value: '',
            type: 'select',
            show: true,
            label: 'Odoo Endpoint',
            errorMsg: '',
            showError: false,
            required: true,
        },
        display_mode: {
            name: 'display_mode',
            value: '1',
            type: 'select',
            obj : [{value : 1, label : 'Horizontal'}, {value : 2, label : 'Vertical'}],
            show: true,
            label: 'Base Endpoint',
            errorMsg: '',
            showError: false,
            required: true,
        },
        host: {
            name: 'host',
            value: '',
            type: 'text',            
            show: true,
            label: 'Local Endpoint',
            errorMsg: '',
            showError: false,
            required: true,
        },
        serial_port: {
            name: 'serial_port',
            value: '',
            type: 'text',            
            show: true,
            label: 'Serial Port',
            errorMsg: '',
            showError: false,
            required: true,
        },
        server_key: {
            name: 'server_key',
            value: '',
            type: 'text',            
            show: true,
            label: 'Server Key',
            errorMsg: '',
            showError: false,
            required: true,
        },
    })
    const [ictIsConnect, setIctIsConnect] = useState(false);
    const [ictStateText, setIctStateText] = useState('');

    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage, page=1) => {
        setIsLoading(true);
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.outlet}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),                    
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }, [setAlertValue, setIsLoading, setPageValue])

    const fetchEndPoint = useCallback(async() => {
        setIsLoading(true);
        try{
            let params = {
                offset: 0,
                limit: 200
            }
            const res = await api.get(`${config.endPoint.masterBaseEndpoint}`, {params: params}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Base Endpoint --'}]
            if (res){
                res.results.map(post => tmp.push({value : post.id, label :  post.url_path}))
            }
            setFormData(formData => ({...formData,
                base_endpoint : {...formData.base_endpoint, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchEndPoint();
    },[fetchEndPoint])


    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleAdd = () => {
        setView('create');
        setIsEdit(false);
        setFormData(formData => ({
            ...formData,
            nama: { ...formData.nama, value: '' },
            id: { ...formData.id, value: '' },
            logo_img : {...formData.logo_img, value : ''},
            logo_display : {...formData.logo_display, value : ''},
            logo : {...formData.logo, value : ''},
            base_endpoint : {...formData.base_endpoint, value : ''},
            invoice_display : {...formData.invoice_display, value : ''},
            display_mode : {...formData.display_mode, value : ''},
            host : {...formData.host, value : ''},
            serial_port : {...formData.serial_port, value : ''},
            server_key : {...formData.server_key, value : ''},
        }));
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === '') {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let countError = 0;
            let obj = { ...formData };
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === '') {
                    countError++;
                    post.showError = true;
                } else {
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0) {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;
            if (isEdit) {
                res = await api.put(`${config.endPoint.outlet}${formData.id.value}/`, newForm).then(res => res.data);
            } else {
                res = await api.post(`${config.endPoint.outlet}`, newForm).then(res => res.data);
            }
            if (res) {
                setView('list');
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to save Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handleEdit = (post) => {
        setFormData(formData => ({
            ...formData,
            nama: { ...formData.nama, value: post.nama },
            id: { ...formData.id, value: post.id },
            logo_img : {...formData.logo_img, value : post.logo_url},
            logo_display : {...formData.logo_display, value : post.logo_display},
            logo : {...formData.logo, value : post.logo},
            base_endpoint : {...formData.base_endpoint, value : post.base_endpoint},
            invoice_display : {...formData.invoice_display, value : post.invoice_display},
            display_mode : {...formData.display_mode, value : post.display_mode},
            host : {...formData.host, value : post.host},
            serial_port : {...formData.serial_port, value : post.serial_port},
            server_key : {...formData.server_key, value : post.server_key},
        }));
        setIsEdit(true);
        setView('create');
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({
            ...modalValue,
            show: true, text: `Apakah anda yakin akan menghapus data group permission ${post.nama} ? `, id: post.id,
            title: 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async () => {
        try {
            const res = await api.delete(`${config.endPoint.outlet}${modalValue.id}/`).then(res => res.data);
            if (res) {
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            handleCloseModal();
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handlePaginate = (page) => {        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }

    const handleShowLogo = () => {        
        modalDispatch({
            type: 'LOGO',
            onClickTr : handleClickLogo
        })
    }

    const handleClickLogo = (post) => {
        setFormData(formData => ({...formData,
            logo : {...formData.logo, value : post.id},
            logo_display : {...formData.logo_display, value : post.url_name},
            logo_img : {...formData.logo_img, value : post.logo_path},
        }))
        closeModalHandler();
    }

    const closeModalHandler = () => {
        modalDispatch({
            type: 'CLOSE'
        })
    }

    const handleCheckConnection = async() => {
        setIsLoading(true);
        setIctStateText('Waiting..');
        setIctIsConnect(false);
        try{
            let tmpFormData = {
                host : formData.host.value,
                serial_port : formData.serial_port.value,
                server_key : formData.server_key.value
            }
            const res = await api.post(`${config.endPoint.checkConnectionIct}`, tmpFormData).then(res => res.data);
            console.log('re is >>>>', res);
            if (res){
                if (res.data && res.data.is_ready){
                    setIctIsConnect(res.data.is_ready)
                    setIctStateText('Connect');
                }else{
                    setIctStateText('Disconnect');
                }
            }
            console.log('res is >>>>>', res);
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }


    return {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading, formData, view, handleCancel, handleAdd, handleChange, handleBlur, handleSubmit,
        handleEdit, handleDelete, handleSubmitDelete, listData, handlePaginate,
        handleChangeSearch, handleSearch, handleKeyDownSearch,
        modalState, handleShowLogo, modalDispatch, closeModalHandler, handleCheckConnection, ictIsConnect,
        ictStateText
    }
}