import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const Button = (props) => {
    const { onClick, color, children, size, className } = props
    return(
        <Fragment>
            <button
                className={`button ${color} ${size} ${className}`}
                onClick={onClick}
            >
                { children }         
            </button>
        </Fragment>
    )
}

Button.propTypes = {
    onClick : PropTypes.func,
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,        
    ]),
    size : PropTypes.string,
    color : PropTypes.string,
    className : PropTypes.string,
}

Button.defaultProps = {
    color : '',
    size : 'md',
}
export default Button;