import React  from 'react';
import PropTypes from 'prop-types';
import ButtonGroup from './globalComponent/ButtonGroup';
// import { UserContext } from '../context/UserContext';

const Modal = (props) => {
    const { isOpen, onClose, size, title, background, onSubmit, onSubmitText, submitColor } = props;

    const getWidth = (params) => {
        if (params === 'xl'){
            return 'w-full'
        }else if (params === 'lg'){
            return 'w-5/6'
        }else if (params === 'md'){
            return 'w-4/5'
        }else {
            return 'w-1/2'
        }
    }

    const getBgColor = (params) => {
        if (params === 'danger'){
            // return "bg-[rgba(254, 242, 242, 0.5)]"
            // return "bg-[rgba(254,242,242,0.8)]"
            return "bg-red-500"
        }else if(params === 'blue'){
            return "bg-blue-500"
        }
    }
    
    return(
        <>
            { isOpen &&
                <div className="flex justify-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="bg-overlay justify-center inset-0 z-49 items-center overflow-x-hidden overflow-y-auto fixed" onClick={onClose}></div>
                    <div className={`relative mx-auto mt-10 ${getWidth(size)} px-16`}>
                        <div className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full ${background} outline-none focus:outline-none`}>
                            <div className="flex items-start justify-between px-5 py-4 border-b border-solid border-gray-300 rounded-t ">
                                <h3 className="text-1xl font=semibold">{title}</h3>
                                <button
                                    className="bg-transparent border-0 text-black float-right"
                                    onClick={onClose}
                                >
                                    <span className="opacity-7 h-6 w-6 text-base block text-font-color  py-0 rounded-full">x
                                    </span>
                                </button>
                            </div>
                            <div className="p-6">
                                {props.children}
                            </div>
                            <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <ButtonGroup>                                    
                                    { onSubmit &&
                                        <button
                                            className={`button text-white ${getBgColor(submitColor)} active:bg-yellow-700 text-sm`}
                                            type="button"
                                            onClick={onSubmit}
                                        >
                                            {onSubmitText}
                                        </button>
                                    }
                                    <button
                                        className="button text-white bg-slate-400 text-sm"
                                        type="button"
                                        onClick={onClose}
                                    >
                                        Close
                                    </button>
                                </ButtonGroup>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

Modal.propTypes = {
    isOpen : PropTypes.bool,
    onClose : PropTypes.func,
    onSubmit : PropTypes.func,
    size : PropTypes.string,    
    title : PropTypes.string,
    background : PropTypes.string,
    onSubmitText : PropTypes.string,
    submitColor : PropTypes.string,
}

Modal.defaultProps = {
    isOpen : false,
    size : 'md',
    background : 'bg-white',
    onSubmitText : 'Submit',
    submitColor : 'blue',
}
export default Modal;