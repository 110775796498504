import PropTypes from 'prop-types';

const ButtonGroup = (props) => {
    const { children } = props;
    return(
        <div className="inline-flex rounded-md shadow-sm button-group" role="group">
            {children}
        </div>
    )
}

ButtonGroup.propTypes = {
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

export default ButtonGroup
