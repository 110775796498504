import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import api from "../../../api";
import config from "../../../config";
import { pageObj } from "../../../generalFunction/pageObj";

export function useGroupPermission(props){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            value : '',
            type : 'text',
            show : true,
            label : 'ID',
            errorMsg : '',
            showError : false,
            required : false,
            readOnly : true,            
        },
        name : {
            name : 'name',
            value : '',
            type : 'select',
            show : true,
            label : 'Name',
            errorMsg : '',
            showError : false,
            required : true,
            obj : [
                {value : '', label : '-- Pilih Group Permission --'},
                { value : 'Administrator', label : 'Administrator'},
                { value : 'Outlet', label : 'Outlet'},
            ],
        },
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
    }
    
    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))            
        }
    }

    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                offset : offset,
                limit : limit
            }
            if (q) {
                params['q'] = q; 
            }
            const res = await api.get(`${config.endPoint.permissionGroup}`, {params: params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            console.log(res.results);
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setAlertValue, setIsLoading, setPageValue])


    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleAdd = () => {
        setView('create');
        setIsEdit(false);
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let obj = {...formData};
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;     
                    console.log('index ', index, post)               
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.permissionGroup}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.permissionGroup}`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to save Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handleEdit = (post) => {
        setFormData(formData => ({...formData,
            name : {...formData.name, value : post.name},
            id : {...formData.id, value : post.id},
        }));
        setIsEdit(true);
        setView('create');
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true, text : `Apakah anda yakin akan menghapus data group permission ${post.name} ? `, id : post.id,
            title : 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async() => {
        try{
            const res = await api.delete(`${config.endPoint.permissionGroup}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }


    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, listData, view, handleAdd, handleCancel, setListData,
        handleChange, handleBlur, formData, handleSubmit, isEdit, handleEdit, handleSubmitDelete, handleDelete
    }
}