import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import config from "../../config";
import api from "../../api";
import { pageObj } from "../../generalFunction/pageObj";

export function useTransaksi(){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');


    const fetchData = useCallback(async(q='', offset=0, limit=config.itemPerPage, cabang_id='', cancel_state = '', paid_state = '', method_payment = '') => {
        setIsLoading(true);
        try{
            let params = {
                offset : offset, 
                limit : limit,
            }
            if (q) {
                params['q'] = q;
            }
            if (cabang_id){
                params['cabang_id'] = cabang_id
            }
            if (cancel_state) {
                params['cancel_state'] = cancel_state;
            }

            if (paid_state) {
                params['paid_state'] = paid_state
            }

            if (method_payment) {
                params['method_payment'] = method_payment
            }
            const res = await api.get(`${config.endPoint.transaksi}`, {params: params}).then(res => res.data);
            if (res){
                setListData(res.results);
                setPageValue(pageValue => ({...pageValue,
                    obj : pageObj(res.count, limit, offset),
                    lastPage : Math.ceil(parseInt(res.count) / parseInt(limit))
                }))
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch data"            
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }

            let status = error && error.response && error.response.status ? error.response.status : '';
            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }

            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false }))
            }, config.timeOutValue)
        }
    },[setPageValue, setIsLoading, setAlertValue])
    
    const fetchcabang = useCallback(async(q='', offset=0, limit=config.itemPerPage) => {
        setIsLoading(true);
        try{
            let params = {
                offset : 0, 
                limit : 300
            }            
            const res = await api.get(`${config.endPoint.outlet}`, {params: params}).then(res => res.data);
            let tmp = [{value : '', label : '-- All Outlet --'}]
            res.results.map(post => tmp.push({value : post.id, label : post.nama}))
            // console.log('cabang length >>>', res.results)
            setPageValue(pageValue => ({...pageValue, 
                cabang_obj : tmp, 
                cabang_id : res.results.length === 1 ? res.results[0].id : ''
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let msg = "Unable to fetch data"            
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }
            setAlertValue(alertValue => ({...alertValue, show: true, text : msg, color : 'danger'}));
            setTimeout(() => {
                setAlertValue(alertValue => ({...alertValue, show: false }))
            }, config.timeOutValue)
        }
    },[setPageValue, setIsLoading, setAlertValue])

    useEffect(() => {
        fetchData();
        fetchcabang();
    },[fetchData, fetchcabang])

    const handleAdd = () => {
        setView('create');
    }

    const handleCancel = () => {
        setView('list');
    }
    

    const handleDelete = (post) => {
        setModalValue(modalValue => ({
            ...modalValue,
            show: true, text: `Apakah anda yakin akan menghapus data group permission ${post.nama} ? `, id: post.id,
            title: 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async () => {
        try {
            const res = await api.delete(`${config.endPoint.outlet}${modalValue.id}/`).then(res => res.data);
            if (res) {
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            handleCloseModal();
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handlePaginate = (page) => {        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage, pageValue.cabang_id, pageValue.cancelState, pageValue.paidState);
    }

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
        if (name === 'cabang_id'){
            fetchData(pageValue.search, 0, config.itemPerPage, value, pageValue.cancelState, pageValue.paidState, pageValue.methodPayment);
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
        if (name === 'paidState'){
            fetchData(pageValue.search, 0, config.itemPerPage, pageValue.cabang_id, pageValue.cancelState, value, pageValue.methodPayment)
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
        if (name === 'cancelState'){
            fetchData(pageValue.search, 0, config.itemPerPage, pageValue.cabang_id, value, pageValue.paidState, pageValue.methodPayment)
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
        if (name === 'methodPayment'){
            fetchData(pageValue.search, 0, config.itemPerPage, pageValue.cabang_id, pageValue.cancelState, pageValue.paidState, value)
            setPageValue(pageValue => ({...pageValue, page : 1, offset : 0}))
        }
    }

    
    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage, pageValue.cabang_id)
    }
    
    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }
    

    const [formConfirm, setFormConfirm] = useState({
        id : {
            name: 'id',
            tipe: 'text',
            value: '',
            label: 'ID',
            required: true,
            show: false,
            showError: false,
        },
        invoice_id_odoo : {
            name: 'invoice_id_odoo',
            tipe: 'text',
            value: '',
            label: 'Invoice Origin',
            required: true,
            show: true,
            showError: false,
        },
        invoice : {
            name: 'invoice',
            tipe: 'text',
            value: '',
            label: 'Invoice',
            required: true,
            show: true,
            showError: false,
        },
        konsumen_name : {
            name: 'konsumen_name',
            tipe: 'text',
            value: '',
            label: 'Customer Name',
            required: true,
            show: true,
            showError: false,
        },
        total : {
            name: 'total',
            tipe: 'text',
            value: '',
            label: 'Total',
            required: true,
            show: true,
            showError: false,
        },
        total_payment : {
            name: 'total_payment',
            tipe: 'number',
            value: '',
            label: 'Total Payment',
            required: true,
            show: true,
            showError: false,
        },
        memo : {
            name: 'memo',
            tipe: 'text',
            value: '',
            label: 'Memo',
            required: true,
            show: true,
            showError: false,
        },
        changes : {
            name: 'changes',
            tipe: 'int',
            value: '',
            label: 'Changes',
            required: false,
            show: true,
            showError: false,
        },
    })

    const handleShowConfirm = (post) => {
        setModalValue(modalValue => ({...modalValue, show: true, title : 'Confirmation'}));
        setFormConfirm(formConfirm => ({...formConfirm,
            id : {...formConfirm.id, value : post.id},
            konsumen_name : {...formConfirm.konsumen_name, value : post.konsumen_name},
            total : {...formConfirm.total, value : post.total.toLocaleString()},
            total_payment : {...formConfirm.total_payment, value : post.total_payment.toLocaleString()},
            invoice_id_odoo : {...formConfirm.invoice_id_odoo, value : post.invoice_id_odoo},
            invoice : {...formConfirm.invoice, value : post.noinvoice},
            memo : {...formConfirm.memo, value : ''},
            changes : {...formConfirm.changes, value : (parseInt(post.total_payment)-parseInt(post.total)).toLocaleString()},
        }))

    }

    const handleSubmitConfirm = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let obj = {...formConfirm};
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;     
                    console.log('index ', index, post)               
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            let res;
            
            res = await api.post(`${config.endPoint.paymentConfirmation}`, newForm).then(res => res.data);
            if (res){
                handleCloseModal();
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage, pageValue.cabang_id, pageValue.cancelState, pageValue.paidState, pageValue.methodPayment);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }
    
    const handleChangeConfirm = (e) => {
        const { name, value } = e.target;
        let nilai = value;
        if (formConfirm[name].tipe === 'number'){
            nilai = nilai.replace(/,/g, '');
        }
        setFormConfirm(formConfirm => ({...formConfirm,
            [name] : {...formConfirm[name], value : nilai}
        }))
    }

    const handleBlurConfirm = (e) => {
        const { name, value } = e.target;
        if (formConfirm[name].required && value === ''){
            setFormConfirm(formConfirm => ({...formConfirm,
                [name] : {...formConfirm[name], showError : true}
            }))
        }else{
            setFormConfirm(formConfirm => ({...formConfirm,
                [name] : {...formConfirm[name], showError : false}
            }))
        }
    }

    const handlePrint = async(post) => {
        setIsLoading(false);
        let dateInvoice = ""
        let amount_untaxed = 0;
        let amount_tax = 0
        try{
            let newForm = new FormData();
            let tmp = {
                'noinvoice': post.invoice_id_odoo
            }
            newForm.append('formData', JSON.stringify(tmp))
            const res = await api.post(`${config.endPoint.invoiceInfo}`, newForm).then(res => res.data);     
            if (res){     
                if (res.payment_detail) {
                    dateInvoice = res.payment_detail.date_invoice        
                    amount_untaxed = res.payment_detail.amount_untaxed
                    amount_tax = res.payment_detail.amount_tax
                }
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
        let tmpObj = {...post};
        tmpObj['date_invoice'] = dateInvoice;
        tmpObj['amount_untaxed'] = amount_untaxed;
        tmpObj['amount_tax'] = amount_tax;
        
        let tmp = JSON.stringify(tmpObj);
        localStorage.setItem('payment_info', tmp);
        return window.open('/cetak', '_blank')        
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, listData, handlePaginate,
        handleSubmitDelete, handleDelete,  
        handleCancel, handleAdd, handleChangeSearch, handleSearch, handleKeyDownSearch, view,
        handleShowConfirm, handleSubmitConfirm, handleChangeConfirm, formConfirm, handleBlurConfirm,
        handlePrint
    }
}