import React from 'react';
import CardComp from '../../../component/globalComponent/CardComp';
import ButtonGroup from '../../../component/globalComponent/ButtonGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { useGroupPermission } from './groupPermissionFunction';
import FormTextSingleComp from '../../../component/globalComponent/FormTextSingleComp';
import AlertComp from '../../../component/alert/AlertComp';
import Modal from '../../../component/Modal';

const GroupPermissionPage = (props) => {
    const { listData, handleAdd, view, handleCancel, formData, handleChange, handleBlur, 
        handleSubmit, pageValue, handleEdit, alertValue, handleCloseAlert, 
        modalValue, handleCloseModal, handleDelete, handleSubmitDelete
    } =  useGroupPermission();

    const buttonNode = () => {
        return(
            <ButtonGroup>
                <button onClick={handleSubmit} className='button bg-red-500  h-full text-white'>Save</button>
                <button onClick={handleCancel} className='button bg-blue-500  h-full text-white'>Cancel</button>
            </ButtonGroup>
        )
    }

    return(
        <div>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <CardComp
                title={view === 'list' ? "Group Permission" : "Add Group Permission"}
                ButtonNode={view === 'create' ? buttonNode : null}
            >
                { view === 'list' &&                    
                    <div className="grid grid-cols-6 mb-5 gap-4 px-5">
                        <div className='col-span-4'>
                            <input className='text-sm appearance-none 
                                block w-full bg-white
                                text-gray-700
                                border-gray-400 
                                rounded py-3 
                                px-2
                                border 
                                leading-tight 
                                focus:outline-1 
                                focus:outline-none 
                                focus:outline-blue-400 
                                focus:border-none'
                            />            
                        </div>
                        <div className="col-span-2">
                            <ButtonGroup>
                                { view === 'list' &&
                                    <button onClick={handleAdd} className='btn-create bg-blue-500  h-full rounded-md text-white'><FontAwesomeIcon icon={faPlus} size='lg'/></button>
                                }
                                
                            </ButtonGroup>
                        </div>
                    </div>
                }
                { view === 'list' &&
                    <div className='px-5'>
                        <table className='table-auto w-full table-border'>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                { listData.map((post, index) => {
                                    return(
                                        <tr key={index}>
                                            <td className='text-right'>{pageValue.offset+1+index}</td>
                                            <td>{post.name}</td>
                                            <td className='text-center'>
                                                <ButtonGroup>
                                                    <button onClick={() => handleEdit(post)} className='button btn-icon bg-blue-400 px-5 py-2 btn-danger text-white'>
                                                        <FontAwesomeIcon icon={faEdit}/>
                                                    </button>
                                                    <button  onClick={() => handleDelete(post)} className='button btn-icon bg-red-400 px-5 py-2 btn-danger text-white'>
                                                        <FontAwesomeIcon icon={faTrashAlt}/>
                                                    </button>
                                                </ButtonGroup>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                }
                { view === 'create' &&
                    <FormTextSingleComp
                        {...formData.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                }
            </CardComp>
            <Modal
                isOpen={modalValue.show}
                onClose={handleCloseModal}
                title={modalValue.title}
                onSubmit={handleSubmitDelete}
                onSubmitText={"Delete"}
                submitColor={"danger"}
            >
                {modalValue.text}
            </Modal>
        </div>        
    )
}
export default GroupPermissionPage;