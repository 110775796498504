import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import config from "../../../config";
import api from "../../../api";

export function useVaBank() {
    const {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [listData, setListData] = useState([]);
    const [view, setView] = useState('list');
    const [formData, setFormData] = useState({
        nama: {
            name: 'nama',
            type: 'text',
            value: '',
            label: 'Nama',
            required: true,
            showError: false,

        },

        logo: {
            name: 'logo',
            value: '',
            type: 'file',
            show: true,
            label: 'Logo',
            errorMsg: '',
            showError: false,
            required: true
        },
        script_kode: {
            name: 'script_kode',
            type: 'text',
            value: '',
            label: 'Kode Script',
            required: true,
            showError: false,
        },
    })

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const res = await api.get(`${config.endPoint.masterVa}`).then(res => res.data);
            if (res) {
                setListData(res.results);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }, [setIsLoading, setAlertValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({
            ...formData,
            [name]: { ...formData[name], value: value }
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === '') {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: true }
            }))
        } else {
            setFormData(formData => ({
                ...formData,
                [name]: { ...formData[name], showError: false }
            }))
        }
    }

    const handleClearForm = () => {
        setFormData(formData => ({
            ...formData,
            nama: { ...formData.nama, value: '' },
            logo: { ...formData.logo, value: '' },
            script_kode: { ...formData.script_kode, value: '' },
        }))
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            let obj = { ...formData };
            let tmpForm = {};
            let countError = 0;

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === '') {
                    countError++;
                    post.showError = true;
                } else {
                    tmpForm[index] = post.value
                }
                return true;
            })

            if (countError > 0) {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi Data', color: 'danger' }));
                setTimeout(() => {
                    handleCloseAlert();
                }, config.timeOutValue)
                setIsLoading(false);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));


            const res = await api.post(`${config.endPoint.masterVa}`, newForm).then(res => res.data);
            if (res) {
                handleClearForm();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            setAlertValue(alertValue => ({
                ...alertValue,
                show: true,
                text: 'Unable to fetch data',
                color: 'danger'
            }))

            setTimeout(() => {
                handleCloseAlert();
            }, config.timeOutValue)
        }
    }

    const handleInsertFoto = (e) => {
        var nfile = e.target.files[0];
        var reader = new FileReader();
        reader.readAsDataURL(nfile);
        reader.onloadend = function () {
            setFormData(formData => ({ ...formData, logo: { ...formData.logo, value: reader.result } }))
        }
    }

    const handleChangeSwith = async (e, index) => {
        setIsLoading(true);
        try {
            const { checked } = e.target;
            let array = [...listData];
            array[index] = { ...array[index], is_active: checked }

            let tmpForm = {
                nama: array[index].nama,
                logo: array[index].logo,
                script_kode: array[index].nama,
                is_active: checked
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm))
            await api.put(`${config.endPoint.masterVa}${array[index].id}/`, newForm).then(res => res.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handleAdd = () => {        
        setView('create');
    }

    const handleCancel = () => {
        if (view === 'list'){
            setView('create');
        }else{
            setView('list');
        }
    }


    return {
        alertValue, setAlertValue, handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal,
        isLoading, setIsLoading,
        handleInsertFoto, formData, handleChange, handleBlur, handleSubmit,
        listData, view, setView,  handleChangeSwith,
        handleAdd, handleCancel
    }
}