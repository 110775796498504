import { Fragment } from "react";
import { useVideo } from "./videoFunction";
import CardComp from "../../../component/globalComponent/CardComp";
import VideoListComp from "./VideoListComp";
import VideomFormComp from "./ViewFormComp";
import ButtonGroup from "../../../component/globalComponent/ButtonGroup";
import { Label } from "../../../component/forms";
import { Player } from "video-react";
import config from "../../../config";
import Modal from "../../../component/Modal";
import AlertComp from "../../../component/alert/AlertComp";
import Button from "../../../component/globalComponent/Button";



const VideoPage = (props) => {    
    const { view, 
        pageValue, handlePaginate, handleSearch, handleChangeSearch, handleKeyDownSearch,
        handleAdd, handleCancel, listData, handleEdit, 
        handleDelete, handleSubmitDelete, handleBlur, handleChange, formData,
        handleSubmit, videoSrc, handleChangeVideo, handleClickVideo, handleCloseModalVideo,
        videoRef, modalVideo, handleCloseModal, modalValue, isEdit,
        alertValue, handleCloseAlert
    } = useVideo();
    

    const buttonNode = () => {
		return (
			<ButtonGroup>
				<Button
					onClick={handleSubmit}
                    color="purple"
				>
					Save
				</Button>
				<Button
					onClick={handleCancel}
					color="light"
				>
					Cancel
				</Button>
			</ButtonGroup>
		);
	};

    return(
        <Fragment>
            <div className="z-12">
                <AlertComp
                    text={alertValue.text}
                    color={alertValue.color}
                    isOpen={alertValue.show}
                    onClose={handleCloseAlert}
                />
                { modalVideo &&
                    <div className="flex justify-center mt-40 overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={handleCloseModalVideo}>
                        <div className="bg-overlay justify-center inset-0 z-49 items-center overflow-x-hidden overflow-y-auto fixed" onClick={handleCloseModalVideo}></div>
                        <div className={`relative mx-auto`} >
                            <video 
                                ref={videoRef} 
                                loop 
                                controls
                                width={"80%"}
                                style={{textAlign: 'center'}}
                                className="mx-auto"
                            >
                                <source src={`${config.apiBaseUrl.video}/video/?tagId=${videoSrc}`} />
                            </video>
                        </div>
                    </div>
                }
                <CardComp title={view === 'list' ? "Video" : "Add Video"} ButtonNode={view === 'create' ? buttonNode : null}>
                    { view === 'list' &&
                        <div className='px-5'>
                            <VideoListComp
                                handleAdd={handleAdd}
                                pageValue={pageValue}
                                handleChangeSearch={handleChangeSearch}
                                handleSearch={handleSearch}
                                handleKeyDownSearch={handleKeyDownSearch}                        
                                view={view}
                                listData={listData}
                                handlePaginate={handlePaginate}
                                handleEdit={handleEdit}
                                handleDelete={handleDelete}
                                handleClickVideo={handleClickVideo}
                            />
                        </div>
                    }
                    { view === 'create' &&
                        <div className='px-5'>
                            <VideomFormComp
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                formData={formData}
                            />
                        </div>
                    }
                    { view === 'create' && isEdit &&
                        <video 
                            ref={videoRef} 
                            loop 
                            controls
                            width={"80%"}
                            style={{textAlign: 'center'}}
                            className="mx-auto mt-10"
                        >
                            <source src={`${config.apiBaseUrl.video}/video/?tagId=${videoSrc}`} />                        
                        </video>
                    }
                    { !isEdit && view === 'create' &&
                        <div className='px-4'>
                            <div className='mb-3 mt-5'>
                                <Label>Input File</Label>
                            </div>
                            <input type='file' onChange={handleChangeVideo}/>
                            { videoSrc !== '' &&
                                <Player
                                    playsInline
                                    src={videoSrc}
                                    fluid={false}
                                    width={480}
                                    height={272}
                                />
                            }
                        </div>
                    }
                </CardComp>
                <Modal
                    isOpen={modalValue.show}
                    onClose={handleCloseModal}
                    title={modalValue.title}
                    onSubmit={handleSubmitDelete}
                    onSubmitText={"Delete"}
                    submitColor={"danger"}
                >
                    {modalValue.text}
                </Modal>
                
            </div>  
        </Fragment>
    )
}

export default VideoPage;