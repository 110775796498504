import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons"

const SearchComp = (props) => {
    const { pageValue, handleChangeSearch,  handleKeyDownSearch} = props;
    return(
        <div className='w-full flex flex-row items-center border px-3 py-2 mb-2' style={{ borderRadius: '8px' }}>
            <div>
                <FontAwesomeIcon icon={faSearch} size="lg" />
            </div>
            <input
                className='text-sm appearance-none 
                    block w-full bg-white
                    text-gray-700
                    border-gray-400                         
                    px-2
                    border-none
                    leading-tight 
                    focus:outline-0
                    focus:outline-none 
                    focus:outline-blue-400 
                    focus:ring-0
                    focus:border-none'
                value={pageValue.search}
                onChange={handleChangeSearch}
                onKeyDown={handleKeyDownSearch}
                placeholder="search"
                name="search"
                style={{ borderTopLeftRadius: '.25rem', borderBottomLeftRadius: '.25rem' }}
            />
        </div>
        
    )
}

SearchComp.propTypes = {
    pageValue : PropTypes.object,
    handleChangeSearch : PropTypes.func,
    // handleSearch : PropTypes.func,
    handleKeyDownSearch : PropTypes.func,
}
export default SearchComp;