import { useCallback, useEffect, useState } from "react";
import api from "../../../api";
import config from "../../../config";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";

export function useMaxVa(props){
    const {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [formData, setFormData] = useState({
        nilai : {
            name : 'nilai',
            value : '',
            type : 'text',
            show : true,
            label : 'Max VA (Rp)',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))
        }
    }

    const fetchData = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.maxVa}`).then(res => res.data);
            console.log('res is >>>>', res);
            if (res){
                if (res.results.length > 0){
                    let myResult = res.results[0];
                    setFormData(formData => ({...formData,
                        nilai : {...formData.nilai, value : myResult.nilai}
                    }))
                }
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);

            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchData();
    },[fetchData])

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let obj = {...formData}
            let countError = 0;
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    post.showError = true;
                    countError++;
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setIsLoading(false);
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Lengkapi data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm))

            const res = await api.post(`${config.endPoint.maxVa}`, newForm).then(res => res.data);
            if (res){
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Data berhasil disimpan', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }

            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, handleChange, handleBlur, handleSubmit, formData
    }
}