import PropTypes from "prop-types";

const CardComp = (props) => {
    const { title, children, ButtonNode } = props;
    return(
        <div className='bg-white drop-shadow-sm border border-gray-100 rounded-sm'>
            <div className='card-header px-5 py-3 font-semibold border-b border-gray-200'>{title}</div>
            <div className="card-body pt-3 pb-7 text-sm">
                {children}
            </div>
            { ButtonNode &&
                <div className='border-t border-gray-300 mt-5 px-5 py-3'>
                    <div className="flex flex-row justify-end text-sm">
                        <ButtonNode/>
                    </div>
                </div>
            }
        </div>
    )
}
CardComp.propTypes = {
    title : PropTypes.string,
    children : PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,        
    ]),
    ButtonNode : PropTypes.func
}
export default CardComp;