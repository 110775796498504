import { Fragment } from "react"
import CardComp from "../../component/globalComponent/CardComp"
import { useChangePassword } from "./changePasswordFunction"
import LoadingComp from "../../component/globalComponent/LoadingComp";
import FormTextSingleComp from "../../component/globalComponent/FormTextSingleComp";
import ButtonGroup from "../../component/globalComponent/ButtonGroup";
import AlertComp from "../../component/alert/AlertComp";

const ChangePasswordPage = (props) => {
    const { 
        isLoading, handleBlur, handleChange, formData, handleShowPassword, 
        handleSubmit, alertValue, handleCloseAlert
    } = useChangePassword();

    const buttonNode = () => {
        return(
            <ButtonGroup>
                <button className='button bg-blue-400 px-5 py-2 btn-danger text-white' onClick={handleSubmit}>Simpan</button>
                <button className='button bg-red-400 px-5 py-2 btn-danger text-white'>Cancel</button>
            </ButtonGroup>
        )
    }
    return(
        <Fragment>
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <div className="z-12">
                <CardComp
                    title={"Change Password"}
                    ButtonNode={buttonNode}
                >
                    <div className='grid grid-cols-2 grid-flow-col px-5'>
                        <div>
                            {Object.entries(formData).map(([index, post]) => {
                                if (post.show) {
                                    return (
                                        <div key={index}>
                                            <FormTextSingleComp
                                                {...post}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                handleShowPassword={handleShowPassword}
                                            />
                                        </div>
                                    )
                                }
                                return true;
                            })}
                        </div>
                    </div>
                </CardComp>
            </div>
            { isLoading &&
                <LoadingComp/>
            }
        </Fragment>
    )
}

export default ChangePasswordPage