import { Fragment } from "react"
import { useLogo } from "./logoFunction"
import AlertComp from "../../../component/alert/AlertComp";
import CardComp from "../../../component/globalComponent/CardComp";
import ReactLoading from 'react-loading';
import FormTextSingleComp from "../../../component/globalComponent/FormTextSingleComp";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt,  } from "@fortawesome/free-regular-svg-icons"
import { faPlus, } from "@fortawesome/free-solid-svg-icons"
import ButtonGroup from "../../../component/globalComponent/ButtonGroup";
import SearchComp from "../../../component/globalComponent/SearchComp";
import Modal from "../../../component/Modal";
import Button from "../../../component/globalComponent/Button";

const LogoPage = (props) => {
    const { 
        isLoading, alertValue, handleCloseAlert, formData,
        handleChange, handleBlur, handleChangeImage,
        view, 
        handleAdd, 
        handleCancel, handleEdit, handleDelete, handleSubmit, 
        listData, pageValue, handleChangeSearch, handleSearch, handleKeyDownSearch,      
        modalValue, handleCloseModal, handleSubmitDelete 
    } = useLogo();

    const buttonNode = () => {
		return (
			<ButtonGroup>
				<Button
					onClick={handleSubmit}
                    color="purple"
				>
					Save
				</Button>
				<Button
					onClick={handleCancel}
					color="light"
				>
					Cancel
				</Button>
			</ButtonGroup>
		);
	};

    return(
        <Fragment>
            <div className="z-12">
                <AlertComp
                    text={alertValue.text}
                    color={alertValue.color}
                    isOpen={alertValue.show}
                    onClose={handleCloseAlert}
                />

                <CardComp title={view === 'list' ? "Logo" : "Add Logo"} ButtonNode={view === 'create' ? buttonNode : null}>
                    { isLoading && 
                        <div className='w-full flex flex-row justify-center'>
                            <ReactLoading type={'cylon'} color="#0366fc" className="text-center"/>
                        </div>                                                 
                    }
                    <div className='px-5'>
                        { view === 'list' &&
                            <div>
                                <div className="grid grid-cols-4 gap-4 mb-4">
                                    <div className='col-span-3'>
                                        <ButtonGroup>
                                            <Button
                                                color="purple-light"
                                                onClick={handleAdd}
                                            >
                                                <FontAwesomeIcon icon={faPlus} /> Add Logo
                                            </Button>                
                                        </ButtonGroup>
                                    </div>
                                    <SearchComp
                                        pageValue={pageValue}
                                        handleChangeSearch={handleChangeSearch}
                                        handleSearch={handleSearch}
                                        handleKeyDownSearch={handleKeyDownSearch}
                                    />
                                </div>
                                <table className='table-auto w-full table'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Logo Name</th>
                                            <th>Logo</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listData.map((post, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td onClick={() => props.onClickTr ? props.onClickTr(post) : null} className='text-right'>{pageValue.offset + 1 + index}</td>
                                                    <td onClick={() => props.onClickTr ? props.onClickTr(post) : null} className={`w-[20%] ${props.onClickTr ? 'cursor-pointer' : ''}`}>
                                                        <div className={`w-[320px]`}>
                                                            <img src={post.logo_path} className='max-w-[100%]' alt="thumbnail"/>
                                                        </div>
                                                    </td>
                                                    <td onClick={() => props.onClickTr ? props.onClickTr(post) : null} className={`${props.onClickTr ? 'cursor-pointer' : ''}`}>{post.url_name}</td>
                                                    <td onClick={() => props.onClickTr ? props.onClickTr(post) : null} className={`text-center`}>
                                                        <ButtonGroup>
                                                            <Button onClick={() => handleEdit(post)} className='button btn-icon bg-blue-400 px-5 py-2 btn-danger text-white' color="primary-outline">
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </Button>
                                                            <Button onClick={() => handleDelete(post)} className='button btn-icon bg-red-400 px-5 py-2 btn-danger text-white' color="danger-outline">
                                                                <FontAwesomeIcon icon={faTrashAlt} />
                                                            </Button>
                                                        </ButtonGroup>
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        }
                        { view === 'create' &&
                            <div>
                                <div>
                                    <FormTextSingleComp
                                        {...formData.url_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                <div>
                                    <FormTextSingleComp
                                        {...formData.logo_path}
                                        onChange={handleChangeImage}
                                        onBlur={handleBlur}
                                    />
                                </div>
                                { formData.logo_path.value &&
                                    <img src={formData.logo_path.value} alt="image_res" className='w-[300px] mt-5 px-5'/>
                                }
                            </div>
                        }
                    </div>
                </CardComp>
                <Modal
                    isOpen={modalValue.show}
                    onClose={handleCloseModal}
                    title={modalValue.title}
                    onSubmit={handleSubmitDelete}
                    onSubmitText={"Delete"}
                    submitColor={"danger"}
                >
                    {modalValue.text}
                </Modal>
            </div>
        </Fragment>
    )
}
export default LogoPage