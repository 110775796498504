import { useRoutes } from 'react-router-dom'
// import mainRoutes from "./mainRoutes";
import DefaultLayout from '../component/DefaultLayout'
import Home from '../views/SamplePage/Home';
import AuthPage from '../views/Auth/AuthPage';
import GroupPermissionPage from '../views/Master/GroupPermission/GroupPermissionPage';
import OutletPage from '../views/Master/Outlet/OutletPage';
import UserPage from '../views/Master/User/UserPage';
import VideoPage from '../views/Master/Video/VideoPage';
import ChangePasswordPage from '../views/Auth/ChangePasswordPage';
import Cookies from 'universal-cookie';
import config from '../config';
import TransaksiPage from '../views/Transaksi/TransaksiPage';
import VaBankPage from '../views/Master/VaBank/VaBankPage';
import MaxVaPage from '../views/Master/MaxVa/MaxVaPage';
import LogoPage from '../views/Master/Logo/LogoPage';
import BaseEndPointPage from '../views/Master/BaseEndPoint/BaseEndPointPage';
import { PrintStrukPage } from '../views/PrintStruk/PrintStrukPage';


const cookies = new Cookies();
let tmp = [
    {
        path: 'dashboard',
        element: <Home />,
        exact : true,
    },    
]

const myCookies = cookies.get(config.cookiesName);

if (myCookies && myCookies.is_superuser) {    
    tmp.push(
        {
            path: 'video',
            element: <VideoPage />,
            exact : true,
        },
        {
            path: 'logo',
            element: <LogoPage />,
            exact : true,
        },
        {
            path: 'outlet',
            element: <OutletPage />,
            exact : true,
        },
        {
            path: 'group-permissions',
            element: <GroupPermissionPage />,
            exact : true,
        },
        {
            path: 'users',
            element: <UserPage />,
            exact : true,
        },
        {
            path: 'master-va',
            element: <VaBankPage />,
            exact : true,
        },
        {
            path: 'maximal-va',
            element: <MaxVaPage />,
            exact : true,
        },
        {
            path: 'base-endpoint',
            element: <BaseEndPointPage />,
            exact : true,
        },
    )            
}

tmp.push(
    {
        path: 'change-password',
        element: <ChangePasswordPage />,
        exact : true,
    },
    {
        path: 'transaksi',
        element: <TransaksiPage />,
        exact : true,
    },    
)

const MainRoutes = {    
    path: '/',
    element: <DefaultLayout />,
    children : tmp
}

const authRoutes = {
    path : '/login',
    element : <AuthPage/>,    
}

const cetakRouters = {
    path: '/cetak',
    element: <PrintStrukPage />,
    exact : true,
}

export default function ThemeRoutes() {
    return useRoutes([MainRoutes, authRoutes, cetakRouters]);
}