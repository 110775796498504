import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt  } from "@fortawesome/free-regular-svg-icons"
import { faPlus, } from "@fortawesome/free-solid-svg-icons"
import SearchComp from '../../../component/globalComponent/SearchComp';
import ButtonGroup from '../../../component/globalComponent/ButtonGroup';
import PropTypes from 'prop-types';
import PagePagination from '../../../component/globalComponent/PagePagination';
import Button from '../../../component/globalComponent/Button';

const VideoListComp = (props) => {
    const { 
        pageValue, handleChangeSearch, handleSearch,
        handleKeyDownSearch, handleAdd, 
        listData, handlePaginate, handleEdit, handleDelete, handleClickVideo
    } = props;
    return(
        <div>
            <div className="grid grid-cols-4 gap-4 mb-4">
                <div className='col-span-3'>
                    <ButtonGroup>
                        <Button
                            color="purple-light"
                            onClick={handleAdd}
                        >
                            <FontAwesomeIcon icon={faPlus} /> Add Video
                        </Button>                
                    </ButtonGroup>
                </div>
                <SearchComp
                    pageValue={pageValue}
                    handleChangeSearch={handleChangeSearch}
                    handleSearch={handleSearch}
                    handleKeyDownSearch={handleKeyDownSearch}
                />    
            </div>
            <div className="relative">
                <table className='table-auto table w-full'>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Video</th>
                            <th>Title</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listData.map((post, index) => {
                            return (
                                <tr key={index}>
                                    <td className='text-right'>{pageValue.offset + 1 + index}</td>
                                    <td className='w-[20%]'>
                                        <div className='w-[320px] cursor-pointer' onClick={() => handleClickVideo(post.id_display)}>
                                            <img src={post.thumbnail_display} className='max-w-[100%]' alt="thumbnail"/>
                                        </div>
                                    </td>
                                    <td>{post.title}</td>
                                    <td className='text-center'>
                                        <ButtonGroup>
                                            <Button onClick={() => handleEdit(post)} className='button btn-icon bg-blue-400 px-5 py-2 btn-danger text-white' color="primary-outline">
                                                <FontAwesomeIcon icon={faEdit} />
                                            </Button>
                                            <Button onClick={() => handleDelete(post)} className='button btn-icon bg-red-400 px-5 py-2 btn-danger text-white' color="danger-outline">
                                                <FontAwesomeIcon icon={faTrashAlt} />
                                            </Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
            <div className='mt-5'>
                <PagePagination
                    data={pageValue.obj}
                    handlePagination={handlePaginate}
                    page={pageValue.page}
                    lastPage={pageValue.lastPage}
                />
            </div>
        </div>
    )
}

VideoListComp.propTypes = {
    pageValue : PropTypes.object.isRequired, 
    handleChangeSearch : PropTypes.func.isRequired, 
    handleSearch : PropTypes.func.isRequired,
    handleKeyDownSearch : PropTypes.func.isRequired, 
    handleAdd : PropTypes.func.isRequired, 
    view : PropTypes.string.isRequired,
    listData : PropTypes.array.isRequired, 
    handlePaginate : PropTypes.func.isRequired,
    handleEdit : PropTypes.func.isRequired,
    handleDelete : PropTypes.func.isRequired,
    handleClickVideo : PropTypes.func.isRequired,
}

export default VideoListComp;