import { useCallback, useEffect, useState } from "react";
import { useGlobalFunction } from "../../../generalFunction/generalFunction";
import api from "../../../api";
import config from "../../../config";
import { pageObj } from "../../../generalFunction/pageObj";

export function useUser(props){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [view, setView] = useState('list');
    const [listData, setListData] = useState([]);

    const [isEdit, setIsEdit] = useState(false);
    const [formData, setFormData] = useState({
        id : {
            name : 'id',
            value : '',
            type : 'text',
            show : false,
            label : 'ID',
            errorMsg : '',
            showError : false,
            required : false,
            readOnly : true,            
        },
        first_name : {
            name : 'first_name',
            value : '',
            type : 'text',
            show : true,
            label : 'Name',
            errorMsg : '',
            showError : false,
            required : true,            
        },
        username : {
            name : 'username',
            value : '',
            type : 'text',
            show : true,
            label : 'Username',
            errorMsg : '',
            showError : false,
            required : true,            
        },
        password : {
            name : 'password',
            value : '',
            type : 'password',
            show : true,
            label : 'Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
        confirm_password : {
            name : 'confirm_password',
            value : '',
            type : 'password',
            show : true,
            label : 'Confirm Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
        group_permissions : {
            name : 'group_permissions',
            value : '',
            type : 'select',
            show : true,
            label : 'Group Permissions',            
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
            obj : []
        }
    })

    const [listGroup, setListGroup] = useState([]);
    const [selectedGroup, setSelectedGroup] = useState([]);
    const [listOutlet, setListOutlet] = useState([]);
    const [selectedOutlet, setSelectedOutlet] = useState([]);
    const [groupName, setGroupName] = useState('');

    const fetchOutlet = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.outlet}`, {params : {offset : 0, limit: 100}}).then(res => res.data);
            if (res){
                setListOutlet(res.results);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue])

    const fetchGroup = useCallback(async() => {
        setIsLoading(true);
        try{
            const res = await api.get(`${config.endPoint.permissionGroup}`, {params : {offset : 0, limit: 100}}).then(res => res.data);
            let tmp = [{value : '', label : '-- Pilih Group Permissions --'}]
            if (res){
                res.results.map(post => tmp.push({value : post.id, label : post.name}))
                setListGroup(res.results);
            }
            setFormData(formData => ({...formData,
                group_permissions : {...formData.group_permissions, obj : tmp}
            }))
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    },[setIsLoading, setAlertValue])

    useEffect(() => {
        fetchOutlet();
        fetchGroup();
    },[fetchOutlet, fetchGroup])


    const fetchData = useCallback(async (q = '', offset = 0, limit = config.itemPerPage, page=1) => {
        setIsLoading(true);
        try {
            let params = {
                offset: offset,
                limit: limit
            }
            if (q) {
                params['q'] = q;
            }
            const res = await api.get(`${config.endPoint.user}`, { params: params }).then(res => res.data);
            if (res) {
                setListData(res.results);
                setPageValue(pageValue => ({
                    ...pageValue,
                    obj: pageObj(res.count, limit, offset),
                    lastPage: Math.ceil(parseInt(res.count) / parseInt(limit)),                    
                }))
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to fetch Data', color: 'error' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }, [setAlertValue, setIsLoading, setPageValue])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    const handleChangeSearch = (e) => {
        const { name, value } = e.target;
        setPageValue(pageValue => ({...pageValue, [name] : value}))
    }

    const handleSearch = () => {
        setPageValue(pageValue => ({...pageValue, offset: 0, page : 1}));
        fetchData(pageValue.search, 0, config.itemPerPage)
    }

    const handleKeyDownSearch = (e) => {
        if (e.key === "Enter"){
            handleSearch();
        }
    }
    
    const handleAdd = () => {
        setView('create');
        setIsEdit(false);
        setSelectedGroup([]);
        setFormData(formData => ({...formData,
            id : {...formData.id, value : ''},
            first_name : {...formData.first_name, value : ''},
            username : {...formData.username, value : ''},
            password : {...formData.password, value : '', required : true},
            confirm_password : {...formData.confirm_password, value : '', required : true},
            group_permissions : {...formData.group_permissions, value : ''},
        }))
        setSelectedOutlet([]);
    }

    const handleEdit = (post) => {
        setFormData(formData => ({...formData,
            id : {...formData.id, value : post.id},
            first_name : {...formData.first_name, value : post.first_name},
            username : {...formData.username, value : post.username},            
            group_permissions : {...formData.group_permissions, value : post.list_group.length > 0  ? post.list_group[0] : ''},
            password : {...formData.password, required : false},
            confirm_password : {...formData.confirm_password, required : false},
        }));
        if (post.list_group_detail.length > 0){
            setGroupName(post.list_group_detail[0].group_name)
        }
        setView('create');
        setIsEdit(true);
        setSelectedOutlet(post.list_outlet)
    }

    const handleCancel = () => {
        setView('list');
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))
        if (name === 'group_permissions'){
            let index = formData.group_permissions.obj.findIndex(x => parseInt(x.value) === parseInt(value));
            let tmpName = ''
            if (index > -1){
                tmpName = formData.group_permissions.obj[index].label;
            }
            setGroupName(tmpName)
            setSelectedOutlet([]);
        }
    }
    
    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))            
        }
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        try{
            let countError = 0;
            let obj = {...formData};
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;                    
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            newForm.append('listOutlet', JSON.stringify(selectedOutlet));
            

            let res;
            if (isEdit){
                res = await api.put(`${config.endPoint.user}${formData.id.value}/`, newForm).then(res => res.data);
            }else{
                res = await api.post(`${config.endPoint.user}`, newForm).then(res => res.data);
            }
            if (res){
                setView('list');
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to save Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    const handlePaginate = (page) => {        
        let myOffset = (parseInt(page) * parseInt(config.itemPerPage)) - parseInt(config.itemPerPage)
        setPageValue(pageValue => ({...pageValue, page : page, offset: myOffset}))
        fetchData(pageValue.search, myOffset, config.itemPerPage);
    }

    const handleClickOutlet = (post) => {
        let array = [...selectedOutlet];
        if (groupName === 'Outlet'){
            array = [];
            array.push(post.id);
        }else{
            if (!array.includes(post.id)){            
                array.push(post.id)
            }else{
                let index = selectedOutlet.findIndex(x => x === post.id);
                array.splice(index, 1);
            }
        }
        setSelectedOutlet(array);
    }
    

    const handleClickGroup = (post) => {
        let array = [...selectedGroup];
        if (!array.includes(post.id)){
            array.push(post.id);
        }else{
            let index = selectedGroup.findIndex(x => x === post.id);
            array.splice(index, 1);
        }
        setSelectedGroup(array);
    }

    const handleShowPassword = (name) => {
        setFormData(formData => ({...formData,
            [name] : {...formData[name], 
                showPassword : !formData[name].showPassword, 
                type : !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }

    const handleDelete = (post) => {
        setModalValue(modalValue => ({...modalValue,
            show: true, text : `Apakah anda yakin akan menghapus user ${post.first_name} ? `, id : post.id,
            title : 'Konfirmasi'
        }))
    }

    const handleSubmitDelete = async() => {
        try{
            const res = await api.delete(`${config.endPoint.user}${modalValue.id}/`).then(res => res.data);
            if (res){
                fetchData(pageValue.search, pageValue.offset, config.itemPerPage);
            }
            handleCloseModal();
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Unable to delete Data', color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    

    

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, listData, view, handleAdd, handleCancel, handleChange, handleBlur, handleSubmit,
        handlePaginate, formData, listGroup, listOutlet, handleClickOutlet, handleClickGroup, handleShowPassword,
        groupName, selectedOutlet, handleDelete, handleSubmitDelete, handleEdit, 
        handleSearch, handleKeyDownSearch, handleChangeSearch
    }
}