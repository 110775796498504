import React, { useEffect, useState } from "react"
import Moment from 'moment'

export const PrintStrukPage = (props) => {
    const myLocal = localStorage.getItem('payment_info') || null;
    const [customerDetail, setCustomerDetail] = useState(null);

    useEffect(() => {
        if (myLocal){
            setCustomerDetail(JSON.parse(myLocal))
        }
        // eslint-disable-next-line
    },[])    

    return (
        <div className='w-[80mm] py-5 px-2'>
            <div className="logo px-2">
                <div className='flex flex-row items-center'>
                    { customerDetail && customerDetail.company_profile && customerDetail.company_profile.logo ?
                        <div className='w-[240px]'>                            
                            <img src={customerDetail.company_profile.logo} alt='logo'/>
                        </div>
                        :
                        <div className='mb-[20px] flex flex-row items-center'>
                            <div className='w-[30px]'>
                                <img src={require('../../assets/turi.png')} alt='logo'/>
                            </div>
                            <div className='flex flex-col ml-[8px]'>
                                <div className='text-blue-600 font-bold text-[14px] tracking-wide'>PT. ASIA SURYA PERKASA</div>
                                <div className='text-[8px] border-t border-t-[blue-600]'>Main Dealer of <span className='text-red-600'>HONDA MOTORCYCLE</span> BANGKA BELITUNG</div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className='text-[12px]  border-b pb-[20px]'>
                <table>
                    <tr>
                        <td className='px-2 py-[2px]'>Tanggal</td>
                        <td className='px-2 py-[2px]'>:</td>
                        <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.date_invoice && Moment(Moment(customerDetail.date_invoice, 'YYYY-MM-DD').toDate()).format('DD-MM-YYYY') }</td>                
                    </tr>
                    <tr>
                        <td className='px-2 py-[2px]'>AHASS</td>
                        <td className='px-2 py-[2px]'>:</td>
                        <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.company_profile && customerDetail.company_profile.ahass_name }</td>                
                    </tr>
                    <tr>
                        <td className='px-2 py-[2px]'>Nomor</td>
                        <td className='px-2 py-[2px]'>:</td>
                        <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.nowo_odoo }</td>                
                    </tr>
                    <tr>
                        <td className='px-2 py-[2px]'>Nama</td>
                        <td className='px-2 py-[2px]'>:</td>
                        <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.konsumen_name }</td>                
                    </tr>
                    <tr>
                        <td className='px-2 py-[2px]'>Status</td>
                        <td className='px-2 py-[2px]'>:</td>
                        <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.is_paid ? 'Sudah dibayar' : 'Belum dibayar'}</td>          
                    </tr>
                    <tr>
                        <td className='px-2 py-[2px]'>Metode Pembayaran</td>
                        <td className='px-2 py-[2px]'>:</td>
                        <td className='px-2 py-[2px]'>{ customerDetail && customerDetail.payment_type_display}</td>          
                    </tr>
                </table>
            </div>
            <div className='mt-[20px] text-[12px] border-b pb-[20px] px-2'>
                <div className='font-[600]'>Detail</div>
                <div className='mt-[10px]'>
                    { customerDetail && customerDetail.detail_transaksi && customerDetail.detail_transaksi.map((post, index) => {
                        return(
                            <div className='flex flex-row justify-between py-[3px]' key={index}>
                                <div>{post.produk_name} x {post.qty}</div>
                                <div>{post.harga.toLocaleString().replace(/,/g, '.')}</div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='mt-[20px] text-[12px]'>
                <div className="text-right">
                    <table className='w-[100%]'>
                        <tr>
                            <td className='py-[2px] px-2 text-right'>Untaxed Amount</td>
                            <td className='py-[2px] px-2 pl-[10px] text-right'>{customerDetail && customerDetail.amount_untaxed && customerDetail.amount_untaxed.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td className='py-[2px] px-2 text-right'>Taxed</td>
                            <td className='py-[2px] px-2 pl-[10px] text-right'>{customerDetail && customerDetail.amount_tax && customerDetail.amount_tax.toLocaleString()}</td>
                        </tr>
                        <tr>
                            <td className='py-[2px] px-2 text-right'>Total</td>
                            <td className='py-[2px] px-2 pl-[10px] text-right font-bold'>{customerDetail && customerDetail.total && customerDetail.total.toLocaleString()}</td>
                        </tr>
                        { customerDetail && customerDetail.payment_type  === 3 &&
                            <>
                                <tr>
                                    <td className='py-[2px] px-2 text-right'>Bayar</td>
                                    <td className='py-[2px] px-2 pl-[10px] text-right font-bold'>{customerDetail.total_payment.toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td className='py-[2px] px-2 text-right'>{ parseInt(customerDetail.total_payment) > parseInt(customerDetail.total) ? 'Kembalian' : 'Kurang Bayar' }</td>
                                    <td className='py-[2px] px-2 pl-[10px] text-right font-bold'>{parseInt(customerDetail.total_payment) > parseInt(customerDetail.total) ? (parseInt(customerDetail.total_payment) - parseInt(customerDetail.total)).toLocaleString() : "(" + (parseInt(customerDetail.total) - parseInt(customerDetail.total_payment)).toLocaleString() + ")" }</td>
                                </tr>
                            </>
                        }
                    </table>                    
                </div>
            </div>
        </div>
    )
};
