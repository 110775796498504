import PropTypes from 'prop-types';
import FormTextSingleComp from '../../../component/globalComponent/FormTextSingleComp';


const VideomFormComp = (props) => {
    const { formData, handleBlur, handleChange } = props;
    return(
        <div className='mt-5'>
            <div className='grid grid-cols-2 gap-5'>
                <div>
                    {Object.entries(formData).map(([index, post]) => {
                        if (post.show) {
                            return (
                                <div key={index}>
                                    <FormTextSingleComp
                                        {...post}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                    />
                                </div>
                            )
                        }
                        return true;
                    })}
                </div>
            </div>
        </div>
    )
}
VideomFormComp.propTypes = {
    formData : PropTypes.object.isRequired,
    handleBlur : PropTypes.func.isRequired,
    handleChange : PropTypes.func.isRequired,
}
export default VideomFormComp;