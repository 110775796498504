import React, { useReducer, useState } from 'react';
import LogoPage from '../views/Master/Logo/LogoPage';

const UserContext = React.createContext([{}, () => {}]);

  
const UserProvider = (props) => {      
    const modalReducer = (state, action) => {
        switch (action.type) {
            case 'OPEN' : 
                return {
                    isOpen : true,
                    text : action.text,
                    size : action.size,
                    content : action.content,
                    title : action.title,
                }
            case 'CLOSE' : 
                return {
                    isOpen : false,
                    text : '',
                    size : null,
                    content : null,
                    title : '',
                }
            case 'LOGO' :
                return {
                    isOpen : true,
                    content : <LogoPage
                        onClickTr={action.onClickTr}
                    />,
                    onClickTr : action.onClickTr
                }
            default : 
                return {
                    isOpen : false,
                    text : '',
                    size : null,
                    content : null,
                    title : '',
                }
        }
    }
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true);
    const [modalState, modalDispatch] = useReducer(modalReducer, {
        isOpen : false,
        text : '',
        size : null,
        content : null,
        title : ''
    })

    return (
        <UserContext.Provider value={{            
            modalState : [modalState, modalDispatch],
            stateSidebar : [sidebarIsOpen, setSidebarIsOpen]
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export { UserContext, UserProvider };