import { Fragment } from "react"
import { useMaxVa } from "./maxVaFunction";
import LoadingComp from "../../../component/globalComponent/LoadingComp";
import AlertComp from "../../../component/alert/AlertComp";
import CardComp from "../../../component/globalComponent/CardComp";
import FormTextSingleComp from "../../../component/globalComponent/FormTextSingleComp";
import ButtonGroup from "../../../component/globalComponent/ButtonGroup";
import Button from "../../../component/globalComponent/Button";

const MaxVaPage = (props) => {
    const { 
        isLoading, alertValue, handleCloseAlert,
        formData, handleChange, handleBlur, handleSubmit
    } = useMaxVa();

    const buttonNode = () => {
        return(
            <ButtonGroup>
                <Button onClick={handleSubmit} className='button bg-red-500  h-full text-white' color="purple-light">Save</Button>
            </ButtonGroup>
        )
    }
    return(
        <Fragment>
            { isLoading && <LoadingComp/>}
            <AlertComp
                text={alertValue.text}
                color={alertValue.color}
                isOpen={alertValue.show}
                onClose={handleCloseAlert}
            />
            <CardComp title="Max VA" ButtonNode={buttonNode}>
                <div className='px-5'>
                    <div className="grid grid-cols-2 mb-5 gap-4">
                        <FormTextSingleComp
                            {...formData.nilai}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="text-right"
                        />
                    </div>
                </div>
            </CardComp>
        </Fragment>
    )
}
export default MaxVaPage;