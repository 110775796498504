import { useState } from "react";
import { useGlobalFunction } from "../../generalFunction/generalFunction";
import config from "../../config";
import api from "../../api";


export function useChangePassword(){
    const { 
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading
    } = useGlobalFunction();
    const [formData, setFormData] = useState({
        password : {
            name : 'password',
            value : '',
            type : 'password',
            show : true,
            label : 'Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
        new_password : {
            name : 'new_password',
            value : '',
            type : 'password',
            show : true,
            label : 'New Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
        confirm_password : {
            name : 'confirm_password',
            value : '',
            type : 'password',
            show : true,
            label : 'Confirm Password',
            inputType : 'password',
            errorMsg : '',
            showError : false,
            required : true,
            showPassword : false,
        },
    })

    const handleShowPassword = (name) => {
        setFormData(formData => ({...formData,
            [name] : {...formData[name], 
                showPassword : !formData[name].showPassword, 
                type : !formData[name].showPassword ? 'text' : 'password'
            }
        }))
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(formData => ({...formData,
            [name] : {...formData[name], value : value}
        }))        
    }
    
    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (formData[name].required && value === ''){
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : true}
            }))
        }else{
            setFormData(formData => ({...formData,
                [name] : {...formData[name], showError : false}
            }))            
        }
    }

    const handleClear = () => {
        setFormData(formData => ({...formData,
            password : {...formData.password, value : ''},
            new_password : {...formData.new_password, value : ''},
            confirm_password : {...formData.confirm_password, value : ''},
        }))
    }

    const handleSubmit = async() => {
        setIsLoading(true);
        console.log('submit >>>')
        try{
            let countError = 0;
            let obj = {...formData};
            let tmpForm = {}

            Object.entries(obj).map(([index, post]) => {
                if (post.required && post.value === ''){
                    countError++;
                    post.showError = true;                    
                }else{
                    tmpForm[index] = post.value;
                }
                return true;
            })

            if (countError > 0){
                setAlertValue(alertValue => ({...alertValue, show: true, text : 'Lengkapi Data', color : 'danger'}));
                setIsLoading(false);
                setTimeout(() => {
                    setAlertValue(alertValue => ({...alertValue, show: false}));
                }, config.timeOutValue);
                return;
            }

            let newForm = new FormData();
            newForm.append('formData', JSON.stringify(tmpForm));
            
            let res = await api.post(`${config.endPoint.changePassword}`, newForm).then(res => res.data);
            
            if (res){
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: 'Password has been changed', color: 'success' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
                handleClear()
            }
            setIsLoading(false);
        }catch(error){
            setIsLoading(false);
            let status = error && error.response && error.response.status ? error.response.status : '';

            let msg = 'Unable to save Data';
            let { response } = error;
            if (response && response.data && response.data.message){
                msg = response.data.message
            }

            if (status && status === 401) {
                return window.location.href = "/login"
            } else {
                setAlertValue(alertValue => ({ ...alertValue, show: true, text: msg, color: 'danger' }));
                setTimeout(() => {
                    setAlertValue(alertValue => ({ ...alertValue, show: false }));
                }, config.timeOutValue)
            }
        }
    }

    return {
        alertValue, setAlertValue,  handleCloseAlert, pageValue, setPageValue, modalValue, setModalValue, handleCloseModal, 
        isLoading, setIsLoading, handleChange, handleBlur, handleShowPassword, handleSubmit, formData
    }
}